/*14. blog-single-page */

.blog-single-page {

    .blog-grid {
        margin-bottom: 30px;
    }

    .blog-slide {

        .image {
            img {
                width: 100%;
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            &::after {
                display: none;
            }
        }

        .swiper-button-next {
            &::before {
                position: absolute;
                width: 50px;
                height: 50px;
                content: "\e649";
                font-family: themify;
                font-size: 18px;
                line-height: 50px;
                right: 20%;
                text-align: center;
                background: $white;
                color: $black;
                transition: all .3s ease-in-out;

                @media (max-width:767px) {
                    width: 35px;
                    height: 35px;
                    font-size: 16px;
                    line-height: 35px;
                }
            }

            &:hover {
                &::before {
                    background: $theme-primary-color;
                    color: $white;
                }
            }
        }

        .swiper-button-prev {
            &::before {
                position: absolute;
                width: 50px;
                height: 50px;
                content: "\e64a";
                font-family: themify;
                font-size: 18px;
                line-height: 50px;
                left: 20%;
                text-align: center;
                background: $white;
                color: $black;
                transition: all .3s ease-in-out;

                @media (max-width:767px) {
                    width: 35px;
                    height: 35px;
                    font-size: 16px;
                    line-height: 35px;
                }

            }

            &:hover {
                &::before {
                    background: $theme-primary-color;
                    color: $white;
                }
            }
        }
    }

    .blog-content {
        position: relative;
        border-bottom: 1px solid #38393C;

        .top-content {
            padding: 20px 40px;

            @media (max-width:1399px) {
                padding: 20px 20px;
            }

            @media (max-width:575px) {
                padding: 10px;
            }


            .tag {
                position: absolute;
                left: 40px;
                top: -20px;
                z-index: 1;

                @media (max-width:1399px) {
                    left: 20px;
                }

                @media (max-width:575px) {
                    left: 10px;
                }

                li {
                    display: inline-block;
                    margin-right: 10px;

                    a {
                        color: $black;
                        text-align: center;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 30px;
                        background: $theme-primary-color;
                        border-radius: 100px;
                        padding: 10px 20px;
                        transition: all .3s ease-in-out;

                        &:hover {
                            background: $white;
                        }
                    }
                }
            }

        }

        .buttom-content {
            padding: 12px 40px;

            @media (max-width:1399px) {
                padding: 12px 20px;
            }

            @media (max-width:575px) {
                padding: 10px;
            }

            ul {
                li {
                    display: inline-block;
                    padding-right: 35px;
                    color: $cyan;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;
                    color: $white;

                    @media (max-width:575px) {
                        padding-right: 15px;
                    }

                    span {
                        padding-right: 10px;
                    }
                }
            }
        }
    }

    .content {
        margin-bottom: 35px;

        h2 {
            color: $heading-color;
            font-size: 40px;
            font-weight: 600;
            line-height: 70px;
            margin-bottom: 20px;

            @media (max-width:767px) {
                font-size: 30px;
                line-height: 40px;
                margin-bottom: 10px;
            }
        }

        h3 {
            color: $heading-color;
            font-size: 35px;
            font-weight: 600;
            line-height: 60px;
            margin-bottom: 20px;

            @media (max-width:767px) {
                font-size: 25px;
                line-height: 30px;
                margin-bottom: 10px;
            }
        }

        p {
            color: $white;
            font-size: 16px;
            font-weight: 300;
            margin-bottom: 20px;
        }
    }

    .blog-single-img {
        .image {
            margin-bottom: 30px;

            img {
                width: 100%;
            }
        }
    }


    /* comment-wrap */
    .comment-wrap {
        @media (max-width:991px) {
            margin-bottom: 50px;
        }

        .comment {
            border-top: 1px solid #38393C;
            border-bottom: 1px solid #38393C;
            padding-top: 50px;

            h2 {
                color: $white;
                font-size: 40px;
                font-weight: 500;
                line-height: 60px;
                margin-bottom: 30px;

                @media (max-width:767px) {
                    font-size: 30px;
                    line-height: 40px;
                }

            }

            ul {

                .grid-comment {
                    display: flex;

                    .image {
                        width: 100px;
                        height: 100px;

                        img {
                            width: 100%;
                            border: 1px solid $theme-primary-color;
                            border-radius: 100%;
                        }
                    }

                    .content {
                        width: calc(100% - 120px);
                        margin-left: 45px;

                        @media (max-width:1399px) {
                            margin-left: 10px;
                        }


                        h3 {
                            color: $heading-color;
                            font-size: 26px;
                            font-weight: 500;
                            line-height: 30px;
                            margin-bottom: 10px;
                        }

                        span {
                            color: $cyan;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 30px;
                            margin-bottom: 10px;
                        }

                        p {
                            color: $heading-color;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 30px;
                            margin-bottom: 30px;
                        }

                        a {
                            color: $black;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 20px;
                            text-transform: uppercase;
                            background: $theme-primary-color;
                            padding: 7px 18px;
                        }
                    }

                }
            }
        }
    }

    .comment-form {
        margin-top: 50px;

        form {
            background: #F3F6F6;
            padding: 60px 65px;
            border: 1px solid #38393C;
            background: #1B1C1E;

            @media (max-width:1399px) {
                padding: 30px 20px;
            }
        }

        h2 {
            color: $heading-color;
            font-size: 40px;
            font-weight: 400;
            line-height: 60px;
            margin-bottom: 35px;
            @media (max-width:767px) {
                    font-size: 30px;
                    line-height: 40px;
                }
        }

        .input-filled {
            margin-bottom: 20px;
        }

        .filled,
        textarea {
            width: 100%;
            height: 60px;
            color: $white;
            font-size: 18px;
            font-weight: 300;
            line-height: 30px;
            padding: 0 20px;
            border: 1px solid #38393C;
            background: #1B1C1E;

            &:focus-visible {
                outline: 0;
            }
        }

        textarea {
            padding: 20px;
            height: 100%;
            height: 184px;
        }


        ::-webkit-input-placeholder {
            color: $white;
        }

        ::-moz-placeholder {
            color: $white;
        }

        :-ms-input-placeholder {
            color: $white;
        }

        :-moz-placeholder {
            color: $white;
        }

    }
}