.theme-btn {
    color: #000;
    text-align: center;
    font-family: $base-font;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    display: inline-block;
    padding: 15px 40px;
    border: 0;
    background: $theme-primary-color;
    transition: all .6s ease-in-out;
    position: relative;
    z-index: 1;
    overflow: hidden;

    @media (max-width:575px) {
        padding: 12px 40px;
        font-size: 16px;
    }

    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        content: '';
        background: rgba(196, 239, 23, 0.932);
        transition: all .6s ease-in-out;
        z-index: -1;
    }

    &:hover {
        color: $white;
        background: transparent;

        &::before {
            width: 100%;
        }
    }
}


.progres-card {

    .block {
        position: relative;
        width: 170px;
        height: 170px;
        border-radius: 50%;
    }

    .box {
        position: relative;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // flex-direction: column;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        border-radius: 50%;
    }

    .box::before {
        position: absolute;
        content: '';
        width: calc(100% + 30px);
        height: calc(100% + 30px);
        border-radius: 50%;
        border: 20px solid #262F01;
    }


    .number {
        color: #FFF;
        text-align: center;
        font-family: $heading-font;
        font-size: 40px;
        font-weight: 400;
        margin-bottom: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        transform: translate(-50%, -50%);

        .sub {
            margin-left: -10px;
            font-size: 30px;
        }
    }


    .dots {
        display: block;
        position: absolute;
        z-index: 2;
        // left: 0;
        // top: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transition: 2s transform, 2s opacity ease;
    }

    .dots::after {
        position: absolute;
        content: '';
        width: 20px;
        height: 20px;
        top: 10px;
        left: 50%;
        border-radius: 50%;
        background-color: $theme-primary-color;
        transform: translateX(-50%);

    }

    .svg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        fill: none;
        transform: rotate(60deg);
    }

    .circle {
        stroke: url(#gradientStyle);
        stroke-width: 20px;
        stroke-dasharray: 472;
        stroke-dashoffset: 472;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        transition: 2s stroke-dashoffset;
        position: relative;

    }

    .title {
        color: #FFF;
        text-align: center;
        font-size: 25px;
        font-weight: 400;
        margin-top: 20px;
    }

}



