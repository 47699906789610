.blog-sidebar {
    padding-left: 10px;
    @media (max-width:1399px) {
        padding-left: 0;
    }
    @media (max-width:991px) {
        max-width: 400px;
    }








    .widget {
        margin-bottom: 60px;
        &:last-child{
            margin-bottom: 0;
        }

        h2 {
            color: $heading-color;
            font-size: 35px;
            font-weight: 300;
            line-height: 45px;
            margin-bottom: 20px;
        }
    }

    .search-widget {
        .search-fild {
            position: relative;

            .fild {
                width: 100%;
                color: $white;
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
                padding: 15px 20px;
                padding-right: 50px;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
                background: #1B1C1E;
                border-radius: 0;
                border: 0;

                &:focus {
                    outline: 0;
                }

                &::-moz-placeholder {
                    color: $white;
                }

                &:-ms-input-placeholder {
                    color: $white;
                }

                &:-moz-placeholder {
                    color: $white;
                }
            }

            button {
                width: 48px;
                height: 48px;
                background: $theme-primary-color;
                border: 0;
                font-size: 18px;
                font-weight: 400;
                line-height: 30px;
                position: absolute;
                right: 5px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .tag-widget {
        ul {
            li {
                display: inline;
                margin: -3px;

                a {
                    color: $white;
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 30px;
                    display: inline-block;
                    position: relative;
                    transition: all .3s ease-in-out;
                    text-transform: capitalize;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
                    background: #1B1C1E;
                    padding: 10px 20px;
                    margin: 4px;

                    &:hover {
                        color: $theme-primary-color;
                    }
                }
            }
        }
    }

    .category-widget {
        ul {
            li {
                padding: 15px 30px;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
                background: #1B1C1E;
                margin-bottom: 10px;

                a {
                    color: $white;
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 30px;
                    margin-bottom: 5px;
                    display: inline-block;
                    position: relative;
                    padding-left: 20px;
                    transition: all .3s ease-in-out;

                    &::before {
                        position: absolute;
                        content: '';
                        left: 0;
                        top: 50%;
                        width: 6px;
                        height: 6px;
                        background: $white;
                        transform: translateY(-50%);
                        transition: all .3s ease-in-out;
                    }

                    &:hover {
                        color: $theme-primary-color;

                        &::before {
                            background: $theme-primary-color;
                        }
                    }
                }
            }
        }
    }

}

.contact-widget {
    position: relative;
    z-index: 1;
    background: url(../../images/contact-widget-bg.jpg) center / cover no-repeat;
    overflow: hidden;
    padding: 30px;
    padding-bottom: 50px;
    text-align: center;

    @media (max-width:1199px) {
        padding: 20px;
        padding-bottom: 40px;
    }

    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.6;
        background: #030012;
        z-index: -1;
        content: '';
    }

    .logo {
        margin-bottom: 50px;

        @media (max-width:1199px) {
            margin-top: 40px;
        }
    }

    h2 {
        color: #FFF;
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
        text-transform: uppercase;

        @media (max-width:1199px) {
            font-size: 15px;
            margin-bottom: 0;
        }
    }

    .call {
        color: #FFF;
        font-size: 30px;
        font-weight: 400;
        line-height: 40px;

        @media (max-width:1199px) {
            font-size: 20px;
        }
    }

    .contact-btn {
        margin-top: 50px;

        @media (max-width:1199px) {
            margin-top: 40px;
        }

        .theme-btn {
            @media (max-width:1199px) {
                padding: 15px 30px;
            }
        }
    }


}


.project-widget {
    padding: 40px 25px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-bottom: 40px;

    @media (max-width:1199px) {
        padding: 20px;
    }

    h3 {
        color: #FFF;
        font-size: 30px;
        font-weight: 400;
        line-height: 50px;
    }

    ul {
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                color: #FFF;
                font-size: 18px;
                font-weight: 400;
                line-height: 40px;
                text-align: left;

                &:last-child {
                    font-weight: 300;
                }
            }

        }
    }
}