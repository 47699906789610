


/* Preloader
-------------------------------------------------------*/

.loader-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $body-bg-color;
    z-index: 111111;
    .loader {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 50px;
        height: 50px;
        font-size: 0;
        color: $theme-primary-color;
        display: inline-block;
        margin: -25px 0 0 -25px;
        text-indent: -9999em;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
    }
    .lead{
      font-size:13px;
    }
    .loader div {
        background-color: $theme-primary-color;
        display: inline-block;
        float: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 50px;
        opacity: .5;
        border-radius: 50%;
        -webkit-animation: ballPulseDouble 2s ease-in-out infinite;
        animation: ballPulseDouble 2s ease-in-out infinite;
    }
    
    .loader div:last-child {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
    }
}


@-webkit-keyframes ballPulseDouble {
    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes ballPulseDouble {
    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}