/* 1.1	Theme Reset Style */
html {
    font-size: 15px;
    overscroll-behavior: smooth;
    scroll-behavior: smooth;
}


body {
    font-family: $base-font;
    color: $body-color;
    background-color: $body-bg-color;
    font-size: 15px;
    overflow-x: hidden;

    @media (max-width:767px) {
        font-size: 14px;
    }
}


p {
    font-size: 16px;
    color: $body-color;
    line-height: 1.8em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $heading-color;
    font-family: $heading-font;
    font-weight: 600;
}

ul {
    padding-left: 0;
    margin: 0;
    list-style: none;
}

a {
    text-decoration: none;
    transition: all .3s;
}

a:hover {
    text-decoration: none;
}

img {
    max-width: 100%;
}


.sr-only {
    display: none !important;
    opacity: 0;
    visibility: hidden;
}

/* 1.2 Global Elements */
.page-wrapper {
    position: relative;
    overflow: hidden;
    margin: auto;
    max-width: 1580px;
}

.wow {
    visibility: hidden;
}

.fi:before {
    margin: 0;
}

.section-padding {
    padding: 120px 0;

    @media (max-width:991px) {
        padding: 90px 0;
    }

    @media (max-width:767px) {
        padding: 80px 0;
    }
}



@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 5s alternate infinite;
    animation: rotating 5s alternate infinite;
}


/*** back to top **/
.back-to-top {
    background-color: transparentize($theme-primary-color, 0.3);
    width: 45px;
    height: 45px;
    line-height: 45px;
    border: 2px solid $theme-primary-color;
    border-radius: 45px;
    text-align: center;
    display: none;
    position: fixed;
    z-index: 999;
    right: 15px;
    bottom: 15px;
    transition: all .3s;

    @media (max-width:991px) {
        width: 35px;
        height: 35px;
        line-height: 35px;
    }


    &:hover {
        background-color: $theme-primary-color;
    }
}

.back-to-top i {
    font-size: 18px;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
}


/** for popup image ***/
.mfp-wrap {
    z-index: 99999;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
}

/*** for fancybox video ***/
.fancybox-overlay {
    background: rgba(0, 0, 0, 0.9);
    z-index: 9999 !important;
}

.fancybox-wrap {
    z-index: 99999 !important;
}


.ball{
 

}

.new_img-animet {
    visibility: hidden;
    overflow: hidden;

    img {
        object-fit: cover;
        transform-origin: left;
    }
}