

/* project-sidebar */

.project-sidebar{
   
      @media (max-width:991px) {
        max-width: 400px;
        margin-top: 50px;
    }
}