/*11. service-single-page */

.service-single-wrap {
    @media (max-width:991px) {
        margin-bottom: 50px;
    }

    .text {
        margin-top: 50px;

        h2 {
            color: $white;
            font-size: 50px;
            font-weight: 400;
            line-height: 60px;
            margin-bottom: 20px;

            @media (max-width:767px) {
                font-size: 40px;
                line-height: 50px;
            }
        }

        p {
            color: $white;
            font-size: 15px;
            font-weight: 400;
            line-height: 30px;

            span {
                color: $theme-primary-color;
                font-size: 30px;
                font-weight: 500;
                line-height: 45px;
                width: 45px;
                height: 45px;
                background: rgba(246, 250, 243, 0.055);
                display: inline-block;
                text-align: center;
            }
        }
    }


    .quote-content {
        padding: 50px 75px;
        background: rgba(246, 250, 243, 0.055);
        position: relative;
        z-index: 1;
        margin: 35px 0 45px;

        @media (max-width:767px) {
            padding: 30px 45px;
        }

        @media (max-width:575px) {
            padding: 20px;
        }

        p {
            font-size: 18px;
            font-weight: 400;
            line-height: 40px;
            margin-bottom: 0;

            @media (max-width:575px) {
                font-size: 15px;
                line-height: 30px;
            }
        }

        .shape {
            position: absolute;
            left: 60px;
            top: 25px;
            z-index: -1;
        }
    }


    .content {

        h3 {
            color: $white;
            font-size: 35px;
            font-weight: 400;
            line-height: 60px;
            margin-bottom: 20px;
        }

        p {
            color: $white;
            font-size: 15px;
            font-weight: 400;
            line-height: 35px;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .image {
            margin-bottom: 30px;

            img {
                width: 100%;
            }
        }

    }
}