/*12. project-single-page */

.project-single-page {

    .content {
        margin-bottom: 40px;

        h2 {
            color: $white;
            font-size: 50px;
            font-weight: 500;
            line-height: 60px;
            margin-bottom: 30px;

            @media (max-width:767px) {
                font-size: 35px;
                line-height: 45px;
            }
        }

        h3 {
            color: $white;
            font-size: 40px;
            font-weight: 400;
            line-height: 50px;
            margin-bottom: 30px;
             @media (max-width:767px) {
                font-size: 30px;
                line-height: 40px;
            }
        }

        p {
            color: $white;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
        }
    }

    .video-wrap {
        background: url(../../images/video-bg-2.jpg) center / cover no-repeat;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width:1199px) {
            height: 400px;
        }

        @media (max-width:575px) {
            height: 300px;
        }

        .popup-video .popup-youtube {
            width: 100px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            &::before {
                position: absolute;
                content: '';
                left: 50%;
                top: 50%;
                width: 90%;
                height: 90%;
                background: $border-color-s2;
                opacity: .5;
                border-radius: 100px;
                transform: translate(-50%, -50%);
            }

            @media (max-width:575px) {
                width: 60px;
                height: 60px;
            }

            span {
                width: 30px;
                height: 40px;
                background: $theme-primary-color;
                clip-path: polygon(0 0, 0% 100%, 100% 50%);
                display: block;
                border-radius: 2px;

                @media (max-width:575px) {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }


    .vider-content {

        .text {
            margin-top: 50px;

            h3 {
                color: $white;
                font-size: 40px;
                font-weight: 400;
                line-height: 50px;
                margin-bottom: 30px;
                 @media (max-width:767px) {
                font-size: 30px;
                line-height: 40px;
            }
            }

            p {
                color: $white;
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
            }
        }
    }

}

// project-single-slider

.project-single-slider {
    margin-bottom: 40px;

    .mySwiper2 {
        margin-bottom: 30px;
    }

    .swiper-wrapper {

        .swiper-slide {
            max-width: 1020px;
            max-height: 640px;
            img {
                width: 100%;

            }
        }
    }

    .mySwiper {
        .swiper-slide {
    
            img {
                width: 100%;
            }
        }

        .swiper-slide-thumb-active {
            position: relative;
            overflow: hidden;
            z-index: 1;

            &::before {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                content: '';
                opacity: 0.5;
                background: $theme-primary-color;
                transition: all .3s ease-in-out;
            }
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        &::after {
            display: none;
        }
    }

    .swiper-button-next {
        &::before {
            position: absolute;
            width: 60px;
            height: 60px;
            content: "\e649";
            font-family: themify;
            font-size: 20px;
            line-height: 60px;
            border: 1px solid transparent;
            border-radius: 100px;
            right: 20%;
            text-align: center;
            background: $white;
            color: $black;
            transition: all .3s ease-in-out;

            @media (max-width:767px) {
                width: 40px;
                height: 40px;
                font-size: 16px;
                line-height: 40px;
            }
        }

        &:hover {
            &::before {
                border: 1px solid $theme-primary-color;
                background: transparent;
                color: $theme-primary-color;
            }
        }
    }

    .swiper-button-prev {
        &::before {
            position: absolute;
            width: 60px;
            height: 60px;
            content: "\e64a";
            font-family: themify;
            font-size: 20px;
            line-height: 60px;
            border: 1px solid transparent;
            border-radius: 100px;
            left: 20%;
            text-align: center;
            background: $white;
            color: $black;
            transition: all .3s ease-in-out;

            @media (max-width:767px) {
                width: 40px;
                height: 40px;
                font-size: 16px;
                line-height: 40px;
            }

        }

        &:hover {
            &::before {
                border: 1px solid $theme-primary-color;
                background: transparent;
                color: $theme-primary-color;
            }
        }
    }

}