/*--------------------------------------------------------------
7. Home-style-4
--------------------------------------------------------------*/

/* 7.1 skill-section-s2 */
.skill-s2 {
    max-width: 695px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 160px;

   

    @media (max-width:991px) {
        padding-bottom: 70px;
    }

    @media (max-width:575px) {
        padding-bottom: 50px;
        flex-wrap: wrap;
    }

    .progres-card{
        margin-bottom: 30px;
    }

}

/*7.2 blog-section-s2 */
.blog-section-s2 {
    position: relative;
    padding-bottom: 160px;
    padding-left: 80px;

    @media (max-width:1399px) {
        padding-left: 50px;
    }

    @media (max-width:991px) {
        padding-left: 30px;
        padding-bottom: 60px;
    }

    @media (max-width:767px) {
        padding-left: 0px;
    }
    @media (max-width:575px) {
        padding: 0 15px;
        padding-bottom: 80px;
    }

    .wraper {
        max-width: 910px;

        @media (max-width:767px) {
            max-width: 600px;
            margin: 0 auto;
        }
    }

    .blog-card {

        .content {
            padding: 30px;

            h2 {
                a {
                    font-size: 35px;
                }
            }
        }
    }

    .line-shape {

        span {

            &::before {
                position: absolute;
                left: -12px;
                bottom: 0;
                width: 25px;
                height: 25px;
                border-radius: 100px;
                background: #2D331D;
                content: '';
            }

            &::after {
                position: absolute;
                left: -7px;
                bottom: 5px;
                width: 15px;
                height: 15px;
                border-radius: 100px;
                background: $theme-primary-color;
                content: '';
            }
        }

    }

}