/*--------------------------------------------------------------
5. Home-style-2 
--------------------------------------------------------------*/

/*5.1 about-section-s2 */
.about-section-s2,
.about-section-s5,
.about-section-s7 {
    padding: 100px 0;

    @media (max-width:991px) {
        padding: 90px 0;
    }

    .about-right {
        max-width: 786px;
        margin-left: -220px;
        position: relative;
        z-index: 1;

        @media (max-width:1599px) {
            margin-left: -150px;
        }

        @media (max-width:991px) {
            margin-left: 0px;
            margin-bottom: 50px;
        }

        .image {
            margin-left: 60px;

            @media (max-width:767px) {
                margin-left: 0px;
            }

            img {
                width: 100%;
                border-radius: 673px;
                max-width: 673px;
                max-height: 673px;
            }
        }

        .shape {
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;

            @media (max-width:767px) {
                display: none;
            }
        }

        .about-experianc {
            border-radius: 65px;
            border: 1px solid #8f65da;
            background: #181E00;
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
            width: 320px;
            height: 130px;
            display: flex;
            align-items: center;
            padding: 10px;
            position: absolute;
            right: 0;
            bottom: 20px;

            @media (max-width:1199px) {
                height: 100px;
            }

            @media (max-width:991px) {
                width: 280px;
            }

            @media (max-width:575px) {
                width: 250px;
                bottom: 0;
                height: 80px;
            }

            .nunber {
                width: 110px;
                height: 110px;
                background: $theme-primary-color;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;

                @media (max-width:1199px) {
                    width: 90px;
                    height: 90px;
                }

                @media (max-width:575px) {
                    width: 70px;
                    height: 70px;
                }

                h3 {
                    color: #000;
                    font-size: 70px;
                    font-weight: 700;
                    margin-bottom: 0;

                    @media (max-width:1199px) {
                        font-size: 50px;
                    }

                    @media (max-width:575px) {
                        font-size: 30px;
                    }
                }
            }

            .text {
                width: calc(100% - 150px);
                margin-left: 10px;

                h4 {
                    color: #FFF;
                    font-size: 25px;
                    font-weight: 500;
                    margin-bottom: 0;

                    @media (max-width:1199px) {
                        font-size: 20px;
                    }

                    @media (max-width:575px) {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .about-left {

        .section-top-content {
            @media (max-width:1199px) {
                margin-bottom: 40px;
            }

            h3 {
                margin-bottom: 30px;

                @media (max-width:1399px) {
                    font-size: 55px;
                }

                @media (max-width:1199px) {
                    font-size: 48px;
                }

                @media (max-width:575px) {
                    font-size: 40px;
                }

                @media (max-width:399px) {
                    font-size: 30px;
                }
            }

            h4 {
                color: #CECECE;
                font-size: 30px;
                font-weight: 400;
                margin-bottom: 25px;

                @media (max-width:1199px) {
                    font-size: 20px;
                }

                span {
                    color: $theme-primary-color;
                }
            }

            p {
                color: #CECECE;
            }
        }

        ul {
            li {
                color: $white;
                font-size: 18px;
                font-weight: 300;
                line-height: 30px;
                list-style: none;
                padding-left: 20px;
                position: relative;
                margin-bottom: 10px;

                @media (max-width:1199px) {
                    font-size: 15px;
                    line-height: 25px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &::before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-radius: 50px;
                    background: $theme-primary-color;
                    transform: translateY(-50%);
                }
            }
        }
    }
}

/*5.2 resume-section-s2 */
.resume-section-s2 {
    position: relative;
    padding: 100px 0;
    padding-top: 0;

    @media (max-width:1199px) {
        padding-bottom: 60px;
    }

    @media (max-width:991px) {
        padding-bottom: 30px;
    }

    .shape {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
    }

&.s3{
    padding: 100px 0;
     @media (max-width:1199px) {
        padding-bottom: 60px;
    }

    @media (max-width:991px) {
        padding-bottom: 30px;
    }
}

    .resume-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1724px;
        margin: 0 auto;

        @media (max-width:1199px) {
            flex-wrap: wrap;
        }


        .item {
            padding-left: 0px;
            padding-bottom: 0px;
            position: relative;
            text-align: center;
            padding-top: 100px;
            width: 25%;

            @media (max-width:1199px) {
                width: 50%;
                margin-bottom: 40px;
            }

            @media (max-width:600px) {
                width: 100%;
            }

            &::before {
                position: absolute;
                left: 50%;
                top: -14px;
                transform: translateX(-50%);
                content: url(../../images/dwn-arros.svg);
            }

            @media (max-width:575px) {
                padding-bottom: 0px;
                padding-left: 0px;
            }

            h2 {

                @media (max-width:1620px) {
                    font-size: 25px;
                }

                @media (max-width:1399px) {
                    font-size: 22px;
                }
            }


            .line-border {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;

                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 50%;
                    height: 1px;
                    background: #ACACAC;
                    content: "";
                    z-index: -1;
                }

                &::after {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 50%;
                    height: 1px;
                    background: #ACACAC;
                    content: "";
                    z-index: -1;
                }

                @media (max-width:1199px) {
                    display: none;
                }



            }

            &:nth-child(1) {
                .line-border {

                    &::before {
                        display: none;
                    }
                }
            }

            &:last-child {
                .line-border {

                    &::after {
                        display: none;
                    }
                }
            }

        }


    }

}

/*5.3 skill-section */
.skill-section,
.skill-section-s2,
.skill-section-s3 {
    padding-top: 110px;
    padding-bottom: 60px;
    background: url(../../images/skill-bg.jpg) center / cover no-repeat;

    @media (max-width:1199px) {
        padding-bottom: 30px;
    }

    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        // background: #141900;
        z-index: -1;
    }

    .progras-card {
        margin-bottom: 30px;
        position: relative;
        text-align: center;

        @media (max-width:1199px) {
            margin-bottom: 60px;
        }

        .percent {
            position: relative;
            width: 100%;
            height: 100%;

        }

        svg {
            position: relative;
            transform: rotate(70deg);
            overflow: visible;

            @media (max-width:1399px) {
                margin-left: -20px;
            }

            @media (max-width:575px) {
                margin-left: 0px;
            }
        }

        svg circle {
            width: 100%;
            height: 100%;
            fill: none;
            stroke: #262F01;
            stroke-width: 16;
            stroke-linecap: round;

            @media (max-width:991px) {
                stroke-width: 14;
            }
        }

        svg circle:last-of-type {
            stroke-dasharray: 360px;
            stroke-dashoffset: calc(360px - (360px * var(--percent)) / 100);
            stroke: $theme-primary-color;
        }

        .number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .number h3 {
            text-align: center;
            font-family: $heading-font;
            margin-bottom: 0;
            color: #FFF;
            font-size: 50px;
            font-weight: 400;

            @media (max-width:575px) {
                font-size: 40px;
            }

            span {
                font-family: $heading-font;
            }
        }


        .title h2 {
            text-align: center;
            font-family: $heading-font;
            margin-bottom: 0;
            margin-top: 40px;
            color: #FFF;
            text-align: center;
            font-size: 30px;
            font-weight: 400;

            @media (max-width:1399px) {
                font-size: 19px;
            }

        }

    }

}


/*5.4 service-section-s2 */
.service-section-s2,
.service-section-s4 {
    position: relative;
    padding-top: 120px;

    @media (max-width:991px) {
        padding-top: 90px;
        padding-bottom: 10px;
    }

    @media (max-width:767px) {
        padding-top: 80px;
        padding-bottom: 0px;
    }

    .shape {
        position: absolute;
        right: 0;
        top: -150px;
        z-index: -1;
    }
}

.skill-section-s3 {
    background: transparent;
}


/*5.5 portfolio-section-s2 */
.portfolio-section-s2 {
    padding-left: 0;

    @media (max-width:991px) {
        padding-bottom: 60px;
    }

    @media (max-width:767px) {
        padding-bottom: 50px;
    }

    .wraper {
        margin: 0 auto;
    }

    .shape {
        left: 0;
        top: 10%;
    }

    .port-top-btn {

        ul {
            justify-content: center;

            @media (max-width:767px) {
                flex-wrap: wrap;
            }

        }
    }
   
}

/*5.6 testimonial-section-s2 */
.testimonial-section-s2 {
    position: relative;
    padding: 0 90px;
    margin-bottom: 230px;

    @media (max-width:991px) {
        padding: 0;
        margin-bottom: 150px;
    }

    &.s3 {
        padding-top: 120px;

        @media (max-width:991px) {
            padding-top: 50px;
        }
    }

    .shape {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    .section-top-content-s2 {
        margin-bottom: 90px;

        @media (max-width:991px) {
            margin-bottom: 60px;

        }

    }

    .slick-list {
        overflow: hidden;
    }

    .slick-slide{
        padding: 0 20px;

        @media (max-width:991px) {
            padding:0 15px;
        }
    }

    .slick-prev {
        left: 47%;
        transform: translateX(-50%);
        top: 112%;

        @media (max-width:1599px) {
            left: 45%;
        }

        @media (max-width:991px) {
            left: 60px;
        }
    }

    .slick-next {
        left: 51%;
        transform: translateX(-50%);
        top: 112%;

        @media (max-width:1599px) {
            left: 53%;
        }

        @media (max-width:991px) {
            left: 130px;
        }
    }

    .testimonial-card {
        margin-top: 65px;
    }
}


/*5.7 pricing-section-s2 */
.pricing-section-s2 {
    position: relative;
    padding-bottom: 90px;
    z-index: 1;

    @media (max-width:991px) {
        padding-bottom: 60px;
    }

    .shape {
        position: absolute;
        bottom: -10%;
        left: 70%;
        transform: translateX(-50%);
        z-index: -1;
    }
}

/*5.8 contact-section-s2 */

.contact-section-s2 {
    position: relative;
    background: url(../../images/contact-bg.jpg) center / cover no-repeat;
    z-index: 1;

    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        // background: #141900;
        z-index: -1;
    }

    .shape {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }

    .shape-start {
        position: absolute;
        left: 150px;
        top: 20%;
        z-index: -1;
        animation: roted 25s linear infinite;
    }

    .shape-2 {
        position: absolute;
        right: 0;
        bottom: -10%;
        z-index: -1;
    }

    .shape-start-2 {
        position: absolute;
        right: 200px;
        bottom: 50px;
        z-index: -1;
        animation: roted 25s linear infinite;
    }
}