/* 1.3 page-wrapper */
.page-wrapper {}

.left-wraper,
.left-wraper-s2 {
    position: fixed;
    top: 30px;
    z-index: 11;
    transition: all 0.5s;
    max-width: 820px;
    width: 100%;
    overflow: hidden;

    @media (max-width:1850px) {
        max-width: 560px;
    }

    @media (max-width:1450px) {
        max-width: 500px;
    }

    @media (max-width:1399px) {
        max-width: 450px;
    }

    @media (max-width:1199px) {
        position: unset;
        margin: 50px auto;
    }

    @media (max-width:575px) {
        margin: 50px auto 0;
    }

    .shape {
        position: absolute;
        left: 0;
        top: 200px;
        z-index: -1;
    }
}

.left-wraper-s2 {
    overflow: visible;
    margin-top: 50px;



    @media (max-width:1550px) {
        max-width: 600px;
        margin-top: 50px;
    }

    @media (max-width:1450px) {
        max-width: 500px;
    }

    @media (max-width:1399px) {
        max-width: 450px;
    }

    @media (max-width:1199px) {
        position: unset;
        margin: 50px auto;
    }

    @media (max-width:575px) {
        margin: 50px auto 0;
    }

    .left-sidetext {
        position: absolute;
        left: -5px;
        top: 0;
        transform: rotate(-90deg);
        width: 100%;
        height: 100%;


        @media (max-width:1750px) {
            left: 20px;
            top: -70px;
        }

        @media (max-width:1550px) {
            display: none;
        }

        h2 {
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #8f65da;
            color: transparent;
            font-size: 100px;
            font-weight: 400;
            margin-bottom: 0;

            @media (max-width:1750px) {
                font-size: 70px;
            }
        }
    }

    .circle-content {
        right: 30px;
        top: 300px;
        z-index: 11;
        background: rgba(38, 47, 1, 0.90);
        border-radius: 100%;

        @media (max-width:1550px) {
            display: none;
        }


        .arrows {
            background: $white;
        }

    }

}

.profile,
.profile-s2 {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    overflow: hidden;

    @media (max-width:1750px) {
        max-width: 550px;
    }

    .image {
        img {
            border-radius: 330px 330px 0px 0px;
            width: 100%;
        }
    }

    .content {
        text-align: center;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        z-index: 1;
        padding-bottom: 40px;

        @media (max-width:575px) {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        &::after {
            position: absolute;
            left: 50%;
            top: -70px;
            width: 300px;
            height: 150px;
            transform: translateX(-50%);
            content: '';
            background: linear-gradient(140deg, rgba(196, 239, 23, 0.36) 13.65%, rgba(114, 142, 0, 0.18) 91.31%);
            backdrop-filter: blur(10px);
            z-index: -11;
            border-radius: 330px 330px 0px 0px;

            @media (max-width:575px) {
                display: none;
            }
        }

        &::before {
            position: absolute;
            left: 0;
            bottom: -80px;
            width: 100%;
            height: 100%;
            content: '';
            background: linear-gradient(140deg, rgba(196, 239, 23, 0.36) 13.65%, rgba(114, 142, 0, 0.18) 91.31%);
            backdrop-filter: blur(10px);
            z-index: -1;

            @media (max-width:575px) {
                bottom: 0;
            }
        }


        h2 {
            color: #FFF;
            font-size: 60px;
            font-weight: 400;
            display: inline-block;
            margin-bottom: 30px;

            @media (max-width:1399px) {
                font-size: 50px;
            }

            @media (max-width:575px) {
                font-size: 30px;
                margin-bottom: 0;
            }
        }

        h3 {
            color: #FFF;
            font-size: 60px;
            font-weight: 400;

            @media (max-width:1399px) {
                font-size: 50px;
            }

            @media (max-width:575px) {
                font-size: 30px;
            }
        }

        p {
            color: #FFF;
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            max-width: 506px;
            margin: 18px auto 35px;

            @media (max-width:575px) {
                font-size: 17px;
            }

            @media (max-width:575px) {
                font-size: 15px;
                margin: 18px auto 10px;
            }
        }

    }

}

.socal-icon {
    li {
        display: inline-block;
        margin: 0 5px;

        a {
            width: 40px;
            height: 40px;
            font-size: 15px;
            line-height: 45px;
            background: $white;
            color: #000;
            text-align: center;
            transition: all .3s ease-in-out;
            display: block;
            border-radius: 100px;

            &:hover {
                background: $theme-primary-color;
                color: $white;
            }
        }
    }
}

.profile-s2 {
    overflow: visible;
    margin-right: auto;
    margin-top: 150px;

    &:before {
        position: absolute;
        left: 20px;
        bottom: 20px;
        width: 100%;
        height: 100%;
        content: '';
        border: 1px solid #FFF;

        @media (max-width:575px) {
            left: 0px;
            bottom: 0px;
        }
    }

    .image {
        img {
            border-radius: 0px;
            width: 100%;
        }
    }

    .content {
        width: 608px;
        text-align: left;
        left: -28px;
        border-radius: 16px;
        z-index: 1500;
        padding: 45px 60px;
        background: #8f65da;

        @media (max-width:1450px) {
            padding: 35px 40px;
        }

        @media (max-width:575px) {
            padding-top: 20px;
            padding-bottom: 20px;
            left: 0px;
            bottom: 0px;
        }

        &::after {
            display: none;
        }

        &::before {
            display: none;
        }


        h2 {
            color: $black;
            margin-bottom: 0px;
            text-align: left;
        }

        h3 {
            color: $black;
            ;
            text-align: left;
            margin-bottom: 15px;
        }

        p {
            color: #3C3C3C;
            max-width: 100%;
            margin: 0;
        }

    }
}

.right-wraper {
    max-width: 1110px;
    position: relative;
    margin-left: auto;

    @media (max-width:1680px) {
        max-width: 991px;
    }

    @media (max-width:1560px) {
        max-width: 900px;
    }

    @media (max-width:1399px) {
        max-width: 700px;
    }

    @media (max-width:1199px) {
        max-width: 800px;
        margin: 0 auto;
    }

    &.s2 {
        @media (max-width:1850px) {
            max-width: 1000px;
        }

        @media (max-width:1750px) {
            max-width: 991px;
        }

        @media (max-width:1650px) {
            max-width: 900px;
        }

        @media (max-width:1550px) {
            max-width: 850px;
        }

        @media (max-width:1399px) {
            max-width: 800px;
        }

        @media (max-width:1299px) {
            max-width: 700px;
        }

        @media (max-width:1199px) {
            max-width: 800px;
            margin: 0 auto;
        }
    }
}


/* 3.2 funfact-section */
.funfact-section {
    max-width: 424px;
    display: flex;
    flex-wrap: wrap;

    .item {
        width: 50%;
        margin-bottom: 45px;

        h3 {
            color: #FFF;
            font-family: Marcellus;
            font-size: 40px;
            font-weight: 400;

            span {
                font-family: Marcellus;
            }
        }

        h5 {
            color: #CCC;
            font-family: $base-font;
            font-size: 20px;
            font-weight: 400;

            @media (max-width:575px) {
                font-size: 15px;
            }
        }
    }
}

/*3.3 about-section */

.about-section {
    position: relative;
    margin-top: 80px;
    padding-bottom: 190px;
    padding-left: 80px;

    @media (max-width:1399px) {
        padding-left: 50px;
    }

    @media (max-width:991px) {
        padding-left: 30px;
        margin-top: 80px;
        padding-bottom: 100px;
    }

     @media (max-width:767px) {
        padding-left: 0px;
    }
    @media (max-width:575px) {
        margin-top: 30px;
        padding: 0 15px;
        padding-bottom: 80px;
    }

    &.s2 {
        margin-top: 0px;

        .section-top-content {
            margin-bottom: 0;
        }
    }

    .wraper {
        max-width: 708px;

        @media (max-width:767px) {
            max-width: 600px;
            margin: 0 auto;
        }

        .section-top-content {
            h3 {
                margin-bottom: 30px;
            }

            h4 {
                color: #CECECE;
                font-size: 30px;
                font-weight: 400;
                margin-bottom: 25px;

                span {
                    color: $theme-primary-color;
                }
            }
        }

        ul {
            li {
                color: $white;
                font-size: 18px;
                font-weight: 300;
                line-height: 30px;
                list-style: none;
                padding-left: 20px;
                position: relative;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                &::before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-radius: 50px;
                    background: $theme-primary-color;
                    transform: translateY(-50%);
                }
            }
        }
    }
}


/*3.4 resume-section */
.resume-section {
    position: relative;
    padding-bottom: 190px;
    padding-left: 80px;

    @media (max-width:1399px) {
        padding-left: 50px;
    }

    @media (max-width:991px) {
        padding-left: 30px;
        padding-bottom: 100px;
    }

    @media (max-width:767px) {
        padding-left: 0px;
    }

    @media (max-width:575px) {
        padding: 0 15px;
        padding-bottom: 80px;
    }

    .wraper {
        @media (max-width:767px) {
            max-width: 600px;
            margin: 0 auto;
        }
    }

    .shape {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
    }

}

.resume-content {
    .item {
        padding-left: 50px;
        padding-bottom: 90px;
        position: relative;

        @media (max-width:575px) {
            padding-bottom: 50px;
            padding-left: 20px;
        }

        span {
            color: $theme-primary-color;
            font-size: 18px;
            font-weight: 500;
        }

        h2 {
            color: $white;
            font-size: 30px;
            font-weight: 400;
            margin: 10px 0;

            @media (max-width:575px) {
                font-size: 25px;
            }
        }

        p {
            color: #CECECE;
            font-size: 18px;
            font-weight: 300;
            line-height: 30px;
            margin-bottom: 0;
        }

        &:last-child {
            padding-bottom: 0;

            .line-shape-2 {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 1px;
                background: transparent;

                // @media (max-width:575px) {
                //     display: none;
                // }

            }
        }
    }

    .line-shape-2 {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 1px;
        background: $theme-primary-color;

        // @media (max-width:575px) {
        //     display: none;
        // }

        &::before {
            position: absolute;
            left: -12px;
            top: 0;
            width: 25px;
            height: 25px;
            border-radius: 100px;
            background: #2D331D;
            content: '';
        }

        &::after {
            position: absolute;
            left: -7px;
            top: 5px;
            width: 15px;
            height: 15px;
            border-radius: 100px;
            background: $theme-primary-color;
            content: '';
        }
    }
}



/*3.5 service-section */

.service-section {
    position: relative;
    padding-bottom: 90px;
    padding-left: 80px;

    @media (max-width:1399px) {
        padding-left: 50px;
    }

    @media (max-width:991px) {
        padding-left: 30px;
        padding-bottom: 0px;
    }

    @media (max-width:767px) {
        padding-left: 0px;
    }

    @media (max-width:575px) {
        padding: 0 15px;
    }


    .wraper {
        max-width: 900px;

        @media (max-width:767px) {
            max-width: 600px;
            margin: 0 auto;
        }

        .row {
            margin: 0 -20px;

            .col {
                padding: 0 20px;
            }
        }
    }

    .section-top-content {
        margin-bottom: 110px;
    }

}

.service-card {
    border: 1px solid #485A00;
    text-align: center;
    padding: 45px 40px;
    margin-bottom: 100px;

    @media (max-width:1399px) {
        padding: 45px 15px;
    }

    @media (max-width:991px) {
        margin-bottom: 80px;
    }

    .icon {
        width: 90px;
        height: 90px;
        line-height: 90px;
        text-align: center;
        background: #262F01;
        border: 1px solid $theme-primary-color;
        border-radius: 100%;
        margin: 0 auto;
        margin-bottom: 25px;
        margin-top: -85px;
    }

    .content {
        h2 {
            margin-bottom: 15px;

            a {
                color: $white;
                font-family: $heading-font;
                font-size: 30px;
                font-weight: 400;
            }
        }

        p {
            color: $white;
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
            margin-bottom: 0;
        }
    }
}


/*3.6 portfolio-section */
.portfolio-section,
.portfolio-section-s2 {
    position: relative;
    padding-bottom: 90px;
    padding-left: 80px;

    @media (max-width:1399px) {
        padding-left: 50px;
    }

    @media (max-width:991px) {
        padding-left: 30px;
        padding-bottom: 0px;
        padding-bottom: 80px;
    }

    @media (max-width:767px) {
        padding-left: 0px;
    }

    @media (max-width:575px) {
        padding: 0 15px;
        padding-bottom: 50px;
    }


    .wraper {
        max-width: 900px;

        @media (max-width:767px) {
            max-width: 600px;
            margin: 0 auto;
        }
    }

    .shape {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }
}

.port-top-btn {
    border: 1px solid #596F01;
    background: #181E00;
    padding-left: 10px;
    margin-bottom: 60px;

    ul {
        display: flex;
        align-items: center;
        justify-content: left;

        @media (max-width:767px) {
            flex-wrap: wrap;
        }

        li {
            button {
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #FFF;
                font-size: 20px;
                font-weight: 400;
                border-radius: 20px;
                border: 1px solid $theme-primary-color;
                padding: 0 30px;
                margin: 20px 10px;
                background: transparent;
                transition: all .3s ease-in-out;

                @media (max-width:1399px) {
                    font-size: 18px;
                    padding: 0 25px;
                }

                @media (max-width:767px) {
                    margin: 10px 10px;
                    font-size: 18px;
                }

                &.active {
                    background: $theme-primary-color;
                    color: $black;
                }
            }
        }
    }
}

.protfolio-card {
    position: relative;
    margin-bottom: 30px;

    .image {

        img {
            width: 100%;
        }
    }

    .text {
        border: 1px solid #637B00;
        background: rgba(27, 28, 30, 0.90);
        padding: 40px 20px;
        text-align: center;
        position: absolute;
        bottom: -30px;
        margin: 15px;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease-in-out;
        left: 47%;
        width: 90%;
        transform: translateX(-50%);

        @media (max-width:1560px) {
            padding: 40px 20px;
        }

        @media (max-width:575px) {
            padding: 30px 10px;
        }

        @media (max-width:399px) {
            left: 45%;
        }

        h2 {
            a {
                color: #FFF;
                font-size: 30px;
                font-weight: 400;
                transition: all .3s ease-in-out;

                @media (max-width:575px) {
                    font-size: 25px;
                }

                &:hover {
                    color: $theme-primary-color;
                }
            }
        }

        span {
            color: $theme-primary-color;
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
        }
    }

    &:hover {
        .text {
            bottom: 0;
            opacity: 1;
            visibility: visible;
        }
    }

}

/*3.7 testimonial-section */
.testimonial-section {
    position: relative;
    padding-bottom: 240px;
    padding-left: 80px;

    @media (max-width:1399px) {
        padding-left: 50px;
    }

    @media (max-width:991px) {
        padding-left: 30px;
    }

    @media (max-width:767px) {
        padding-left: 0px;
    }

    @media (max-width:575px) {
        padding: 0 15px;
        padding-bottom: 200px;
    }


    .wraper {
        max-width: 910px;

        @media (max-width:767px) {
            max-width: 600px;
            margin: 0 auto;
        }
    }

    .shape {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
    }
}

.testimonial-slider,
.testimonial-slider-s2 {

    .slick-prev,
    .slick-next {
        width: 50px;
        height: 50px;
        transition: all .3s ease-in-out;
        z-index: 1;
        border: 1px solid #D9D9D9;
        background: #FFF;
        border-radius: 0;
        top: 115%;

        &:hover {
            border: 1px solid transparent;
            background: $theme-primary-color;
        }
    }

    .slick-prev {
        left: 0px;

    }

    .slick-next {
        left: 60px;
    }

    .slick-list {
        overflow: visible;
    }

    .slick-prev:before,
    .slick-next:before {
        color: #050505;
        content: "\e629";
        font-family: themify;
        font-size: 18px;
    }

    .slick-next:before {
        content: "\e628";
    }

    .slick-dots {
        bottom: -48px;
    }

    .slick-dots li {
        margin: 0;
    }

    button {
        display: block;
        width: 10px;
        height: 10px;
        padding: 0;
        border: none;
        border-radius: 100%;
        background-color: rgba(43, 17, 15, 0.10);

        &::before {
            font-size: 0;
        }
    }

    li.slick-active button {
        background-color: $theme-primary-color;
    }
}

.testimonial-card {
    border: 1px solid $theme-primary-color;
    position: relative;
    padding: 60px 80px 40px;
    z-index: 1;

    @media (max-width:1399px) {
        padding: 50px 50px 40px;
    }

    @media (max-width:991px) {
        padding: 30px 20px 30px;
    }

    .top-content {
        display: flex;
        align-items: center;
        justify-content: left;
        max-width: 450px;
        margin-bottom: 30px;

        @media (max-width:575px) {
            flex-direction: column;
            align-items: start;
        }

        .image {
            width: 120px;
            height: 120px;
            padding: 10px;
            border-radius: 100%;
            border: 2px solid #4A5C00;

            img {
                width: 100%;
                border-radius: 100%;
            }
        }

        .text {
            margin-left: 20px;

            @media (max-width:575px) {
                margin-left: 0;
                margin-top: 20px;
            }

            h3 {
                color: #FFF;
                font-size: 40px;
                font-weight: 500;
                line-height: 60px;

                @media (max-width:575px) {
                    font-size: 30px;
                    line-height: 40px;
                }
            }

            span {
                color: #C7C7C7;
                font-size: 22px;
                font-weight: 300;
                line-height: 30px;

            }
        }
    }

    p {
        color: #FFF;
        font-size: 20px;
        font-weight: 300;
        line-height: 38px;

        @media (max-width:399px) {
            font-size: 18px;
            line-height: 30px;
        }
    }

    .icon {
        width: 130px;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #1F2700;
        border-radius: 100%;
        border: 1px solid $theme-primary-color;
        position: absolute;
        right: 65px;
        top: -65px;

        @media (max-width:767px) {
            width: 80px;
            height: 80px;
            right: 20px;
            top: -40px;
        }

        img {
            @media (max-width:767px) {
                width: 60%;
            }
        }
    }
}

/* 3.8 pricing-section */

.pricing-section {
    position: relative;
    padding-bottom: 190px;
    padding-left: 80px;

    @media (max-width:1399px) {
        padding-left: 50px;
    }

    @media (max-width:991px) {
        padding-left: 30px;
        padding-bottom: 100px;
    }

    @media (max-width:767px) {
        padding-left: 0px;
    }

    @media (max-width:575px) {
        padding: 0 15px;
        padding-bottom: 80px;
    }

    .wraper {
        max-width: 910px;

        @media (max-width:767px) {
            max-width: 600px;
            margin: 0 auto;
        }
    }

    .shape {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }

    .line-shape {

        span {

            &::before {
                position: absolute;
                left: -12px;
                bottom: 0;
                width: 25px;
                height: 25px;
                border-radius: 100px;
                background: #2D331D;
                content: '';
                
            }

            &::after {
                position: absolute;
                left: -7px;
                bottom: 5px;
                width: 15px;
                height: 15px;
                border-radius: 100px;
                background: $theme-primary-color;
                content: '';
            }
        }

    }

}

.pricing-card {
    border-radius: 220px 220px 0px 0px;
    border: 1px solid #8f65da;
    background: #141A00;
    text-align: center;
    margin-bottom: 30px;

    .top-content {
        padding: 60px 0;
        border-bottom: 1px solid #8f65da;

        span {
            color: #FFF;
            font-size: 25px;
            font-weight: 400;
            line-height: 30px;
            text-transform: uppercase;
            margin-bottom: 15px;
        }

        h2 {
            color: #8f65da;
            font-size: 60px;
            font-weight: 400;
            margin-bottom: 0;

            span {
                color: #FFF;
                font-size: 20px;
                font-weight: 400;
                line-height: 30px;
                font-family: $base-font;
                text-transform: capitalize;
            }
        }
    }

    .buttom-content {
        padding: 40px 0 60px;

        ul {

            li {
                color: #BCBCBC;
                font-family: $base-font;
                font-size: 18px;
                font-weight: 400;
                line-height: 30px;
                margin-bottom: 20px;

                @media (max-width:1199px) {
                    font-size: 16px;
                }
            }
        }

        a {
            color: #000;
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            border: 1px solid $theme-primary-color;
            background: $theme-primary-color;
            transition: all .3s ease-in-out;
            position: relative;
            overflow: hidden;
            padding: 15px 40px;
            z-index: 1;
            margin-top: 20px;
            display: inline-block;
            cursor: pointer;

            &::before {
                position: absolute;
                left: 0;
                top: 0;
                width: 0;
                height: 100%;
                content: '';
                background: rgba(196, 239, 23, 0.932);
                transition: all .6s ease-in-out;
                z-index: -1;
            }

            &:hover {
                color: $white;
                background: transparent;

                &::before {
                    width: 100%;
                }
            }
        }
    }
}


/*3.9 contact-section */
.contact-section {
    position: relative;
    padding-bottom: 70px;
    padding-left: 80px;
    margin-top: -20px;

    @media (max-width:1399px) {
        padding-left: 50px;
        padding-bottom: 70px;
    }

    @media (max-width:991px) {
        padding-left: 30px;
        padding-bottom: 70px;
    }

    @media (max-width:767px) {
        padding-left: 0px;
    }

    @media (max-width:575px) {
        padding: 0 15px;
        padding-bottom: 70px;
    }

    .wraper {
        max-width: 910px;

        @media (max-width:767px) {
            max-width: 600px;
            margin: 0 auto;
        }
    }

    .shape {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateX(-50%);
        z-index: -1;
    }



}

.error-message {
    display: none;
    color: red;
}

.contact-from {
    border: 1px solid #435303;
    background: #141900;
    padding: 40px;
    margin-top: 30px;

    @media (max-width:575px) {
        padding: 20px;
    }

    .form-control {
        margin-bottom: 20px;
        width: 100%;
        border: 1px solid #374500;
        color: #FFF;
        font-size: 18px;
        font-weight: 300;
        line-height: 30px;
        padding: 15px 20px;
        background: transparent;
        border-radius: 0;

        &:focus {
            outline: none;
            box-shadow: 0 0 0 0.25rem transparent;
        }

        option {
            color: $black;
        }
    }

    textarea {
        height: 150px;
    }

    .form-btn {
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        display: block;
        padding: 15px 60px;
        border: 0;
        margin: 0 auto;
        margin-top: 30px;
        background: $theme-primary-color;
        transition: all .6s ease-in-out;
        position: relative;
        z-index: 1;

        @media (max-width:575px) {
            padding: 15px 40px;
        }

        &::before {
            position: absolute;
            left: 0;
            top: 0;
            width: 0;
            height: 100%;
            content: '';
            background: rgba(196, 239, 23, 0.932);
            transition: all .6s ease-in-out;
            z-index: -1;
        }

        &:hover {
            color: $white;
            background: transparent;

            &::before {
                width: 100%;
            }
        }
    }

    ::-webkit-input-placeholder {
        color: #FFF;
    }

    ::-moz-placeholder {
        color: #FFF;
    }

    :-ms-input-placeholder {
        color: #FFF;
    }

    :-moz-placeholder {
        color: #FFF;
    }
}

.contact-info {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .icon {
        width: 60px;
        height: 60px;
        background: #1f27009c;
        border: 1px solid #8f65da;
        text-align: center;
        line-height: 55px;
        border-radius: 100%;
    }

    .text {
        margin-left: 10px;
        width: calc(100% - 70px);

        h3 {
            color: #FFF;
            font-size: 22px;
            font-weight: 400;
        }

        span {
            color: #ADADAD;
            font-size: 16px;
            font-weight: 300;
            line-height: 30px;
        }
    }
}