/*16. error-page-section */

.error-page-section {

    .content {
        max-width: 400px;
        text-align: center;
        margin: 0 auto;
        margin-top: -20px;

        h2 {
            color: $heading-color;
            font-size: 150px;
            font-weight: 400;
            @media (max-width:991px) {
                font-size: 100px;
            }
            @media (max-width:757px) {
                font-size: 80px;
            }
        }

        h3 {
            color: $white;
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 20px;
            @media (max-width:575px) {
                font-size: 25px;
            }
        }

        p {
            font-size: 16px;
            font-weight: 400;
        }
        .theme-btn{
            margin-top: 20px;
        }
    }
}