/*15. contact-page-section */

.contact-page-section {


    .contact-form {
        background: #F3F6F6;
        padding: 70px 65px;
        border: 1px solid #38393C;
        background: #1B1C1E;
        max-width: 800px;
        margin-left: auto;

        @media (max-width:1399px) {
            padding: 70px 20px;
            max-width: 100%;
        }


        h2 {
            color: $heading-color;
            font-size: 60px;
            font-weight: 400;
            line-height: 80px;
            margin-bottom: 0;

            @media (max-width:767px) {
                font-size: 40px;
            }
        }

        span {
            color: $theme-primary-color;
            font-size: 25px;
            font-weight: 400;
            line-height: 45px;
            margin-bottom: 40px;
            display: block;

            @media (max-width:767px) {
                font-size: 20px;
                line-height: 30px;
            }
        }

        .input-filled {
            margin-bottom: 20px;
        }

        .filled,
        textarea {
            width: 100%;
            height: 60px;
            color: $white;
            font-size: 18px;
            font-weight: 300;
            line-height: 30px;
            padding: 0 20px;
            border: 1px solid #38393C;
            background: #1B1C1E;

            &:focus-visible {
                outline: 0;
            }
        }

        textarea {
            padding: 20px;
            height: 100%;
            height: 184px;
        }


        ::-webkit-input-placeholder {
            color: $white;
        }

        ::-moz-placeholder {
            color: $white;
        }

        :-ms-input-placeholder {
            color: $white;
        }

        :-moz-placeholder {
            color: $white;
        }

        .theme-btn {
            text-transform: capitalize;
        }
    }


    .contact-info-wrap {
        max-width: 330px;
        margin-left: 20px;
        margin-bottom: 80px;

        @media (max-width:1199px) {
            margin-left: 0;
        }

        @media (max-width:991px) {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        ul {
            display: flex;
            align-content: space-between;
            flex-direction: column;
        }

        .contact-info {
            border: 1px solid #38393C;
            padding: 35px 25px;

            @media (max-width:1199px) {
                padding: 30px 15px;
            }

            @media (max-width:991px) {}

            .content {
                margin-left: 25px;

                h3 {
                    color: $heading-color;
                    font-size: 25px;
                    font-weight: 500;
                    line-height: 30px;
                }

                span {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 32px;
                }
            }
        }
    }


    .contact-mape-section {}

    .map-wrap {
        max-width: 1650px;
        height: 614px;
        margin: 0 auto;
        position: relative;

        @media (max-width:991px) {
            height: 500px;
        }
        @media (max-width:757px) {
            height: 400px;
        }
        iframe {
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            position: absolute;
        }
    }
}