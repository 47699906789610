/*4. footer-section */


.footer-section {
    padding: 100px 0 70px;
    position: relative;
    background: url(../../images/contact-bg.jpg) center / cover no-repeat;
    z-index: 1;
    overflow: hidden;

    @media (max-width:767px) {
        padding: 80px 0 50px;
    }

    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        // background: #141900;
        z-index: -1;
    }

    .shape {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }

    .shape-start {
        position: absolute;
        left: 150px;
        top: 50%;
        z-index: -1;
        animation: roted 25s linear infinite;

        @media (max-width:767px) {
            display: none;
        }
    }

    .shape-2 {
        position: absolute;
        right: 0;
        bottom: -10%;
        z-index: -1;
    }

    .shape-start-2 {
        position: absolute;
        right: 200px;
        bottom: 50px;
        z-index: -1;
        animation: roted 25s linear infinite;

        @media (max-width:991px) {
            display: none;
        }
    }

    .widget {
        margin-bottom: 30px;

        h2 {
            color: #FFF;
            font-family: $heading-font;
            font-size: 40px;
            font-weight: 600;
            line-height: 60px;
            margin-bottom: 25px;

            @media (max-width:991px) {
                font-size: 35px;
                line-height: 50px;
                margin-bottom: 20px;
            }

            @media (max-width:757px) {
                font-size: 30px;
                line-height: 40px;
            }
        }

        ul {
            list-style: none;

            li {
                list-style: none;
                font-size: 18px;
                font-weight: 300;
                line-height: 30px;
                margin-bottom: 10px;
                color: #EEE;

                img {
                    margin-right: 10px;
                }
            }
        }
    }


    .f-about-widget {
        text-align: center;

        @media (max-width:991px) {
            text-align: left;
        }

        .logo {
            margin-bottom: 30px;
            display: block;
        }

        p {
            font-size: 18px;
            font-weight: 300;
            line-height: 30px;
            margin-bottom: 40px;
        }
    }

    .social-widget {
        ul {
            list-style: none;

            li {
                display: inline-block;
                margin: 0 10px;

                a {
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    border-radius: 100px;
                    color: #fff;
                    font-size: 18px;
                    background: rgba(255, 255, 255, 0.1);
                    display: block;
                    transition: all .3s ease-in-out;
                    text-align: center;

                    &:hover {
                        background: $theme-primary-color;
                        color: $black;
                    }
                }
            }
        }
    }

    .f-services-widget {
        text-align: end;

        @media (max-width:991px) {
            text-align: left;
        }

        ul {
            li {
                margin-bottom: 0;

                a {
                    color: #EEE;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 30px;
                    transition: all .3s ease-in-out;

                    &:hover {
                        color: $theme-primary-color;
                    }
                }
            }
        }
    }




}